
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import { Toaster } from 'react-hot-toast';
import { useState } from 'react';
import Home_page from './Components/Home_page/Home_page';

import { Route, Routes } from 'react-router-dom';

function App() {
  const [raise, setRaise] = useState(0)
  return (
    <div className="App">
      <Toaster />
      <Header />
      <Routes>
        <Route path='/' element={<Home_page />} />
      </Routes>


      <Footer />

    </div>
  );
}

export default App;
