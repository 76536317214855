import React, { useEffect, useState } from "react";
import "./Tab_page.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Web3 from "web3";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import { useAccount } from "wagmi";
import {
  BUSD_Contract_ABI,
  BUSD_Contract_Address,
  Swap_Contract_Abi,
  Swap_Contract_address,
  Swap_Token_Contract_Abi,
  Swap_Token_Contract_address,
  preSale_Contract_ABI,
  preSale_Contract_Address,
} from "../Contract/Contract";
import toast from "react-hot-toast";
import usdt from "../Assets/usdt.svg";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tab_page() {
  const [value, setValue] = React.useState(1);
  const { address } = useAccount();
  const [getBalance, setgetBalance] = useState(0);
  const [getBNB_value, setgetBNB_value] = useState("");
  const [showToken, setshowToken] = useState(0);
  const [YtokenPrice, setYtokenPrice] = useState(0);

  const [spinner, setspinner] = useState(false);
  const [plan, setplan] = useState(0);

  const webSupply = new Web3(
    "https://1rpc.io/bnb"
    // "https://1rpc.io/bnb"

    // https://1rpc.io/bnb
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange_value = (e) => {
    setgetBNB_value(e.target.value);
  };

  const balanceOf = async () => {
    try {
      let TokenContractOf = new webSupply.eth.Contract(
        Swap_Token_Contract_Abi,
        Swap_Token_Contract_address
      );
      let ContractOf = new webSupply.eth.Contract(
        Swap_Contract_Abi,
        Swap_Contract_address
      );
      let bnbpriceinUSD= await ContractOf.methods.getLatestPriceBNB().call();
      let tokenpriceInUSD= await ContractOf.methods.TokenPricePerToken().call();
      let YtokenPrices=bnbpriceinUSD/tokenpriceInUSD
      setYtokenPrice(YtokenPrices);
      console.log("YtokenPrice",YtokenPrices);
      if (address) {
        let value_BNB = webSupply.utils.toWei(
          getBNB_value == "" ? "0" : getBNB_value.toString()
        );
        let bnb_Balance = await TokenContractOf.methods.balanceOf(address).call();
        bnb_Balance = webSupply.utils.fromWei(bnb_Balance.toString());
        setgetBalance(bnb_Balance.toString());
        let checktokenXToTokenY = await ContractOf.methods
        .checktokenXToTokenY(value_BNB)
        .call();
        console.log("checktokenXToTokenY", checktokenXToTokenY);
        checktokenXToTokenY = webSupply.utils.fromWei(checktokenXToTokenY.toString());
        setshowToken(checktokenXToTokenY);
     

      }
    } catch (error) {
      console.log(error);
    }
  };

  const swapToken = async () => {
    try {
      if (getBNB_value == "") {
        toast.error("Please Enter Amount First!");
        setspinner(false);
      } else {
        if (getBNB_value > getBalance) {
          toast.error("Insufficient Balance");
          setspinner(false);
        } else {
          if (!address) {
            toast.error("Please Connect Metamaske First!");
          } else {
            setspinner(true);
            let swapAmount;
            swapAmount = webSupply.utils.toWei(getBNB_value.toString());
            const { request } = await prepareWriteContract({
              address: Swap_Token_Contract_address,
              abi: Swap_Token_Contract_Abi,
              functionName: "approve",
              args: [Swap_Contract_address, swapAmount.toString()],
              account: address,
            });
            const { hash } = await writeContract(request);
            const data = await waitForTransaction({
              hash,
            });

            setTimeout(async () => {
              toast.success("Approve Successful");
              const { request } = await prepareWriteContract({
                address: Swap_Contract_address,
                abi: Swap_Contract_Abi,
                functionName: "swap",
                args: [swapAmount.toString()],
                account: address,
              });
              const { hash } = await writeContract(request);
              const data = await waitForTransaction({
                hash,
              });
              setspinner(false);
              toast.success("Swap Successful");
            }, 3000);
          }
        }
      }
    } catch (e) {
      console.log("Error", e);
      setspinner(false);
    }
  };

  useEffect(() => {
    balanceOf();
  }, [getBNB_value, address]);

  return (
    <div className="ab_main">
      {/* <div className="first_Box_stking d-flex gap-2">

      </div> */}
     
      <div className="balnce_text text-center text-black">
      <h2>🟢 Live Rate</h2>  1 KASBLT = {parseFloat(YtokenPrice.toString()).toFixed(3)} KASETF Token
      </div>
      <div className="balnce_text">
        Your wallet : {parseFloat(getBalance.toString()).toFixed(3)}
      </div>

      {/* <div className="balnce_text">
        1 Y token  = {parseFloat(YtokenPrice.toString()).toFixed(3)} X tokens
      </div> */}

      <div className="box_busd ">
        <div className="d-flex justify-content-between">
          <p>Amount </p>
          <p
            style={{ cursor: "pointer" }}
            onClick={() =>
              setgetBNB_value(
                getBalance !== "0" ? Number(getBalance) - Number(0.0001) : 0
              )
            }
          >
            MAX
          </p>
        </div>

        <input
          type="number"
          placeholder="0"
          value={getBNB_value}
          onChange={handleChange_value}
        />
      </div>

      <div className="box_busd mt-2">
        <p>Tokens</p>
        <input type="text" disabled placeholder="0" value={showToken} />
      </div>
      <div>
        <button className="buy_BTN" onClick={swapToken}>
          {spinner ? "Loading..." : "Swap"}
        </button>
      </div>
    </div>
  );
}
