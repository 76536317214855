import React from 'react'
import { useState } from 'react'
import Landing_page from '../Landing_page/Landing_page'



// import { FaQ } from 'react-icons/fa6'

export default function Home_page() {
  const [raise, setRaise] = useState(0)

  return (
    <div>
    <Landing_page setRaise={setRaise} />
    </div>
  )
}
