import React, { useEffect, useState } from "react";
import "./Landing_page.css";
import Tab from "../Tab_page/Tab_page";

export default function Landing_page() {
  return (
    <div className="main_div_landing">
      <div className="main_landing">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 this_ha">
              <div className="landing_box">
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="info_pre text-black" style={{fontFamily:"'Fugaz One', sans-serif !important"}}>
                      <h1  style={{fontFamily:"'Fugaz One', sans-serif !important"}}>BURN KASETF GET KASBLT </h1>
                    </div>
                  </div>
                </div>

                <div className="buy_chain_box">
                  <Tab />
                </div>
                {/* <div className='bg_landing'></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
